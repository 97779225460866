<template>
  <div>
    <b-card>
      <!-- {{ deviceArea }} / {{ deviceAreaOptions }} -->
      <div class="d-flex justify-content-between align-items-center mb-1">
        <h4 class="mb-0">
          權限設定
        </h4>

        <div>
          <b-button
            variant="outline-primary"
            size="sm"
            @click="submitSaveUser"
          >
            儲存
          </b-button>
        </div>
      </div>

      <div>
        <b-row>
          <b-col
            cols="12"
            md="5"
            class="mb-2"
          >
            <div class="d-flex justify-content-between align-items-center mb-50">
              <h5>可瀏覽人員</h5>

              <div class="text-nowrap d-flex justify-content-end">
                <div
                  v-b-tooltip.hover.focus.v-secondary
                  title="重新整理"
                  class="d-flex align-items-center actions-link-btn ml-25"
                  @click="resetUserArray()"
                >
                  <b-img
                    src="/admin/images/table/refresh.svg"
                    class="actions-link-btn-image"
                    rounded
                  />
                </div>
              </div>
            </div>
            <div class="component-list border">
              <draggable
                v-if="!isCardBusy && !isBusy"
                :list="userArray"
                tag="div"
                group="user"
                class="list-group list-group-flush cursor-move"
                :empty-insert-threshold="300"
                style="min-height: 300px;"
                handle=".draggable-handle"
              >
                <!-- @add="submitCPUPullAdd($event)" -->
                <div
                  v-for="(item, index) in userArray"
                  :key="index"
                  class="component-area mb-1"
                >
                  <div class="d-flex justify-content-between align-items-center component-area-content draggable-handle">
                    <div class="d-flex justify-content-start align-items-center">
                      <div class="mr-1">
                        <b-avatar
                          size="40"
                          variant="light-primary"
                        />
                      </div>

                      <div class="profile-user-info cursor-pointer">
                        <h6
                          class="mb-0"
                        >
                          <span class="text-primary">{{ item.name }}</span>
                          <small
                            v-if="item.department_info.id"
                            style="word-break: break-all"
                          >
                            ( {{ item.department_info.name }} )
                          </small>
                        </h6>

                        <small
                          style="word-break: break-all"
                          class="text-muted"
                        >{{ item.account }}</small>
                      </div>
                    </div>

                    <div
                      class="actions-link-btn-sm mr-25"
                      @click="submitRemoveUser(item)"
                    >
                      <b-img
                        v-b-tooltip.hover.focus.v-secondary
                        title="移除"
                        src="/admin/images/table/swap.svg"
                        class="actions-link-btn-image"
                        rounded
                      />
                    </div>
                  </div>
                </div>

                <div
                  v-if="userArray.length === 0"
                  class="loading-area"
                >
                  <div class="text-center my-5 animate__animated animate__fadeIn">
                    <b-img
                      :src="$store.state.app.notFoundImg"
                      fluid
                      width="480"
                      alt="查無資料"
                    />
                  </div>
                </div>
              </draggable>

              <div
                v-else
                class="loading-area"
              >
                <b-img
                  src="/admin/images/common/loading-2.png"
                  rounded
                  height="60"
                  width="60"
                />
              </div>
            </div>
          </b-col>

          <b-col
            cols="12"
            md="2"
            class="d-flex align-items-center flex-md-column justify-content-center my-2"
          >
            <div class="mb-50 my-1">
              <feather-icon
                icon="ArrowRightIcon"
                class="cursor-pointer text-muted d-md-block d-none"
                size="16"
                @click="submitRemoveAllUser()"
              />

              <feather-icon
                icon="ArrowUpIcon"
                class="cursor-pointer text-muted d-md-none d-block"
                size="16"
                @click="submitAddAllUser()"
              />
            </div>

            <div class="mb-50 my-1">
              <feather-icon
                icon="ArrowLeftIcon"
                class="cursor-pointer text-muted d-md-block d-none"
                size="16"
                @click="submitAddAllUser()"
              />

              <feather-icon
                icon="ArrowDownIcon"
                class="cursor-pointer text-muted d-md-none d-block"
                size="16"
                @click="submitRemoveAllUser()"
              />
            </div>
          </b-col>

          <b-col
            cols="12"
            md="5"
            class="mb-2"
          >
            <div class="d-flex justify-content-between align-items-center mb-50">
              <h5>員工列表</h5>

              <div class="text-nowrap d-flex justify-content-end">
                <div
                  v-b-tooltip.hover.focus.v-secondary
                  title="重新整理"
                  class="d-flex align-items-center actions-link-btn ml-25"
                  @click="refetchTable"
                >
                  <b-img
                    src="/admin/images/table/refresh.svg"
                    class="actions-link-btn-image"
                    rounded
                  />
                </div>

                <!-- <div
                  v-b-tooltip.hover.focus.v-secondary
                  title="篩選器"
                  class="d-flex align-items-center actions-link-btn ml-25"
                >
                  <b-dropdown
                    variant="link"
                    toggle-class="p-0"
                    no-caret
                    right
                  >
                    <template #button-content>
                      <b-img
                        src="/admin/images/table/filter.svg"
                        class="actions-link-btn-image"
                        rounded
                      />
                    </template>

                    <b-dropdown-form style="min-width: 300px">
                      <b-form-group>
                        <label class="mb-50">
                          搜尋
                        </label>

                        <b-input-group class="input-group-merge">
                          <b-input-group-prepend is-text>
                            <feather-icon icon="SearchIcon" />
                          </b-input-group-prepend>
                          <b-form-input
                            v-model="searchQuery"
                            debounce="500"
                            class="d-inline-block"
                            placeholder="搜尋..."
                          />
                          <b-input-group-append
                            v-if="searchQuery"
                            is-text
                          >
                            <feather-icon
                              icon="XIcon"
                              class="cursor-pointer text-muted"
                              @click="searchQuery = null"
                            />
                          </b-input-group-append>
                        </b-input-group>
                      </b-form-group>

                      <b-form-group>
                        <label class="mb-50">
                          品牌
                        </label>
                        <v-select
                          v-model="searchBrand"
                          :options="brandsOptions"
                          label="name"
                          :reduce="option => option.id"
                          placeholder="請選擇品牌"
                        >
                          <template v-slot:no-options="{ search, searching }">
                            <template v-if="searching">
                              沒有<em>{{ search }}</em> 的相關結果
                            </template>

                            <div v-else>
                              <small>暫無資料</small>
                            </div>
                          </template>
                        </v-select>
                      </b-form-group>

                      <b-form-group>
                        <label class="mb-50">
                          購買日期
                        </label>
                        <b-input-group class="input-group-merge mr-1">
                          <flat-pickr
                            v-model="purchaseTimeRange"
                            class="form-control"
                            :config="dateConfig2"
                            placeholder="YYYY-MM-DD"
                          />

                          <b-input-group-append
                            v-if="purchaseTimeRange"
                            is-text
                          >
                            <feather-icon
                              icon="XIcon"
                              class="cursor-pointer text-muted"
                              @click="purchaseTimeRange = null"
                            />
                          </b-input-group-append>
                        </b-input-group>
                      </b-form-group>
                    </b-dropdown-form>

                  </b-dropdown>
                </div> -->
              </div>
            </div>

            <div>
              <div class="component-list border">
                <draggable
                  v-if="!isTableBusy && !isBusy"
                  :list="tableDataFilter"
                  tag="div"
                  group="user"
                  class="list-group list-group-flush cursor-move"
                  :empty-insert-threshold="300"
                  style="min-height: 300px;"
                  handle=".draggable-handle"
                >
                  <!-- @add="submitCPUPullRemove($event)" -->
                  <div
                    v-for="(item, index) in tableDataFilter"
                    :key="index"
                    class="component-area mb-1"
                  >
                    <div class="d-flex justify-content-between align-items-center component-area-content draggable-handle">
                      <div class="d-flex justify-content-start align-items-center">
                        <div class="mr-1">
                          <b-avatar
                            size="40"
                            variant="light-primary"
                          />
                        </div>

                        <div class="profile-user-info cursor-pointer">
                          <h6
                            class="mb-0"
                          >
                            <span class="text-primary">{{ item.name }}</span>
                            <small
                              v-if="item.department_info.id"
                              style="word-break: break-all"
                            >
                              ( {{ item.department_info.name }} )
                            </small>
                          </h6>

                          <small
                            style="word-break: break-all"
                            class="text-muted"
                          >{{ item.account }}</small>
                        </div>
                      </div>

                      <div
                        class="actions-link-btn-sm mr-25"
                        @click="submitAddUser(item)"
                      >
                        <b-img
                          v-b-tooltip.hover.focus.v-secondary
                          title="新增"
                          src="/admin/images/table/swap.svg"
                          class="actions-link-btn-image"
                          rounded
                        />
                      </div>
                    </div>
                  </div>

                  <div
                    v-if="tableDataFilter.length === 0"
                    class="loading-area"
                  >
                    <div class="text-center my-5 animate__animated animate__fadeIn">
                      <b-img
                        :src="$store.state.app.notFoundImg"
                        fluid
                        width="480"
                        alt="查無資料"
                      />
                    </div>
                  </div>
                </draggable>

                <div
                  v-else
                  class="loading-area"
                >
                  <b-img
                    src="/admin/images/common/loading-2.png"
                    rounded
                    height="60"
                    width="60"
                  />
                </div>
              </div>

              <!-- 顯示頁數/分頁 -->
              <div class="mx-2 m-1">
                <div class="d-flex align-items-center justify-content-center">
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="totalNum"
                    :per-page="perPage"
                    first-number
                    last-number
                    class="mb-0 mt-1 mt-sm-0"
                    prev-class="prev-item"
                    next-class="next-item"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BImg, VBTooltip, VBToggle, BPagination, BAvatar, BButton,
  // BTableSimple, BTr, BTd, BCollapse,
  // BDropdown, BDropdownForm, BFormGroup, BInputGroupAppend, BInputGroup,
  // BInputGroupPrepend, BFormInput,
} from 'bootstrap-vue'
import draggable from 'vuedraggable'
import { useSwalToast, useAlert } from '@/libs/mixins/index'
import { useUserModal, useTodoGroupView } from '../../useTodo'

export default {
  components: {
    BRow,
    BCol,
    BImg,
    BCard,
    BButton,
    BPagination,
    BAvatar,
    draggable,
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-toggle': VBToggle,
  },
  mixins: [useSwalToast],
  props: {
    isCardBusy: {
      type: Boolean,
      required: true,
    },
    groupDataItem: {
      type: Object,
      required: true,
    },
    groupUserArray: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      missingFields: [],
    }
  },
  computed: {
    authAbility() {
      return this.$store.state.app.ability
    },
    tableDataFilter() {
      // return this.tableData
      const userIds = this.userArray.map(user => user.id)
      return this.tableData.filter(item => !userIds.includes(item.id))
    },
  },
  mounted() {
    this.resetUserArray()
  },
  methods: {
    refreshDeviceData() {
      if (this.isCardBusy) return
      this.$emit('refresh-data')
    },

    // (移除)使用者
    submitRemoveUser(item) {
      const index = this.userArray.findIndex(user => user.id === item.id)
      if (index !== -1) {
        this.userArray.splice(index, 1)
      }
    },

    // (新增)使用者
    submitAddUser(item) {
      const idExists = this.userArray.some(user => user.id === item.id)

      if (!idExists) {
        this.userArray.push(item)
      }
    },

    // (移除)所有使用者
    submitRemoveAllUser() {
      this.userArray = []
    },

    // (新增)所有使用者
    submitAddAllUser() {
      this.tableDataFilter.forEach(element => {
        this.submitAddUser(element)
      })
    },

    // (觸發)儲存
    submitSaveUser() {
      const currentUserIds = this.userArray.map(user => user.id)
      const groupUserIds = this.groupUserArray.map(user => user.id)

      // 找出新增的 ID：存在於 currentUserIds 但不在 groupUserIds 中
      const createId = currentUserIds.filter(id => !groupUserIds.includes(id))

      // 找出被刪除的 ID：存在於 groupUserIds 但不在 currentUserIds 中
      const deleteId = groupUserIds.filter(id => !currentUserIds.includes(id))

      const resolve = {
        create_id: createId,
        delete_id: deleteId,
      }
      this.isBusy = true

      this.setTodoGroupUserData({
        group_id: this.groupDataItem.id,
        data: {
          ...resolve,
        },
      })
        .then(response => {
          this.useHttpCodeAlert(response)
          this.$emit('refresh-data', null)
          this.isBusy = false
        })
        .catch(error => {
          this.useHttpCodeAlert(error.response)
          this.isBusy = false
        })
    },

    // (獲取)可瀏覽人
    resetUserArray() {
      this.userArray = JSON.parse(JSON.stringify(this.groupUserArray))
    },
  },
  setup() {
    const {
      isBusy,
      userArray,
      setTodoGroupUserData,
    } = useTodoGroupView()

    const {
      tableData,
      isTableBusy,
      statusOptions,
      refDataListTable,
      perPage,
      totalNum,
      currentPage,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      tableColumns,
      dataMeta,
      refetchTable,

      setUserDelete,
      setUserCreate,
      setUserUpdate,
      getUserListData,
    } = useUserModal()

    const {
      useAlertToast,
      useHttpCodeAlert,
    } = useAlert()

    getUserListData()

    return {
      useAlertToast,
      useHttpCodeAlert,

      userArray,
      tableData,
      isTableBusy,
      statusOptions,
      refDataListTable,
      perPage,
      totalNum,
      currentPage,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      tableColumns,
      dataMeta,
      refetchTable,

      setUserDelete,
      setUserCreate,
      setUserUpdate,
      getUserListData,

      isBusy,
      setTodoGroupUserData,
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>

<style lang="scss" scoped>
.device-area-select {
  width: 150px;
}

.component-list {
  padding: 15px;
  height: 100%;
  border-radius: 10px;
  height: 60vh;
  overflow-y: scroll;
  .component-area {
    background-color:#cfcfcf2b;
    border-radius: 10px;
    .component-area-content {
      padding: 15px;
    }
  }
}

.loading-area {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 50vh;
}
</style>
