<template>
  <b-modal
    id="update-modal"
    no-close-on-backdrop
    ok-title="確認"
    centered
    header-bg-variant="primary"
    ok-only
    @hidden="handleHide"
    @close="handleHide"
  >
    <template #modal-title>
      <h4 class="m-0 model-header">
        {{ groupDataItem.id ? '編輯分類' : '新增分類' }}
      </h4>
    </template>

    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <!-- 公共 -->
        <b-form-group label-for="status">
          <template #label>
            <div class="d-flex justify-content-between align-items-center">
              <label class="mb-0">
                公共
                <span class="text-danger">*</span>
              </label>
            </div>
          </template>

          <v-select
            v-model="groupData.public"
            :options="publicOptions"
            :clearable="false"
            :reduce="option => option.value"
            placeholder="請選擇公共狀態"
          >
            <template v-slot:no-options="{ search, searching }">
              <template v-if="searching">
                沒有<em>{{ search }}</em> 的相關結果
              </template>

              <div v-else>
                <small>暫無資料</small>
              </div>
            </template>
          </v-select>
        </b-form-group>

        <!-- 名稱 -->
        <validation-provider
          #default="{ errors }"
          name="名稱"
          rules="required"
        >
          <b-form-group label-for="name">
            <template #label>
              <label class="mb-0">
                名稱
                <span class="text-danger">*</span>
              </label>
            </template>
            <b-form-input
              id="name"
              v-model="groupData.name"
              :state="errors.length > 0 ? false : null"
              trim
              :placeholder="groupDataItem.name ? groupDataItem.name : '請輸入名稱'"
            />

            <b-form-invalid-feedback>
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <!-- 群組說明 -->
        <div>
          <label>群組說明</label>
          <b-form-textarea
            v-model="groupData.description"
            :placeholder="groupDataItem.description ? groupDataItem.description : '請輸入群組權限說明'"
            :state="groupData.description.length <= maxChar ? null : false"
            rows="5"
          />
          <small
            class="textarea-counter-value float-right"
            :class="groupData.description.length > maxChar ? 'bg-danger' : ''"
          >
            <span class="char-count">{{ groupData.description.length }}</span> / {{ maxChar }}
          </small>
        </div>
      </b-form>
    </validation-observer>

    <template #modal-footer>
      <div class="w-100 text-right">
        <b-button
          variant="primary"
          :disabled="isBusy"
          @click="handleOk"
        >
          <span v-if="!isBusy">確認</span>

          <b-spinner
            v-else
            small
          />
        </b-button>
      </div>
    </template>

  </b-modal>
</template>

<script>
// API
import { ref } from '@vue/composition-api'
import { required, password } from '@validations'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'

// UI
import {
  BForm, VBToggle, BButton, BSpinner, BFormInvalidFeedback, BFormGroup, BFormInput, BFormTextarea,
  VBTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'

// Component
import formValidation from '@core/comp-functions/forms/form-validation'
import { useTodoGroupList, useTodoSetting } from '../../useTodo'
import { useAlert } from '@/libs/mixins/index'

export default {
  components: {
    BForm,
    // BFormGroup,
    BButton,
    BSpinner,
    BFormInvalidFeedback,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    // BFormSpinbutton,

    // VueSlider,
    // flatPickr,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },
  props: {
    groupDataItem: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      password,
      missingFields: [],
      maxChar: 255,
    }
  },
  methods: {
    // 按下彈窗關閉
    handleHide(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.reseTodoTypeData()
      this.isBusy = false
      this.$nextTick(() => {
        this.$bvModal.hide('update-modal')
      })
    },

    // 按下彈窗確認
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault()

      if (this.groupData.description.length > this.maxChar) {
        this.useAlertToast(false, '群組權限說明字數超過上限')
        return
      }

      // 判斷必填欄位是否填寫
      const requiredFields = {
        name: '名稱',
      }

      this.missingFields = []

      for (let i = 0; i < Object.keys(requiredFields).length; i += 1) {
        if (this.groupData[Object.keys(requiredFields)[i]] === null || this.groupData[Object.keys(requiredFields)[i]] === '') {
          this.missingFields.push({
            lable: Object.values(requiredFields)[i],
            value: Object.keys(requiredFields)[i],
          })
        }
      }

      if (this.missingFields.length > 0) {
        this.useAlertToast(false, `${this.missingFields[0].lable}不得為空`)
        return
      }

      this.isBusy = true

      if (this.groupDataItem.id) {
        this.setTodoGroupUpdate({
          group_id: this.groupDataItem.id,
          data: {
            ...this.groupData,
          },
        })
          .then(response => {
            this.useHttpCodeAlert(response)
            this.$emit('refetch-data')
            this.reseTodoTypeData()
            this.$nextTick(() => {
              this.$bvModal.hide('update-modal')
            })
            this.isBusy = false
          })
          .catch(error => {
            this.useHttpCodeAlert(error.response)
            this.isBusy = false
          })
      } else {
        this.setTodoGroupCreate({
          ...this.groupData,
        })
          .then(response => {
            this.useHttpCodeAlert(response)
            this.$emit('refetch-data')
            this.reseTodoTypeData()
            this.$nextTick(() => {
              this.$bvModal.hide('update-modal')
            })
            this.isBusy = false
          })
          .catch(error => {
            this.useHttpCodeAlert(error.response)
            this.isBusy = false
          })
      }
    },

    // (獲取)資料
    getData() {
      const resolveData = {
        ...this.syncObject(this.blankGroupData, this.groupDataItem),
      }
      this.groupData = resolveData
      this.$bvModal.show('update-modal')
    },
  },
  setup() {
    localize('tw')

    const {
      isBusy,
      blankGroupData,
      setTodoGroupCreate,
      setTodoGroupUpdate,
    } = useTodoGroupList()

    const {
      syncObject,
      publicOptions,
    } = useTodoSetting()

    const {
      useAlertToast,
      useHttpCodeAlert,
    } = useAlert()

    const groupData = ref(JSON.parse(JSON.stringify(blankGroupData)))

    const reseTodoTypeData = () => {
      groupData.value = JSON.parse(JSON.stringify(blankGroupData))
      isBusy.value = false
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(reseTodoTypeData)

    return {
      isBusy,

      groupData,
      blankGroupData,
      setTodoGroupCreate,
      setTodoGroupUpdate,

      reseTodoTypeData,
      refFormObserver,
      getValidationState,
      resetForm,

      useHttpCodeAlert,
      useAlertToast,
      syncObject,
      publicOptions,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-slider.scss';
</style>

<style lang="scss" scoped>
.model-header {
  color: white;
  padding: 5px 0;
}
</style>
