<template>
  <div v-if="groupInfo">
    <group-info
      :is-card-busy="isBusy"
      :group-data-item="groupInfo"
      @refresh-data="refreshGroupData"
    />

    <group-content
      :is-card-busy="isBusy"
      :group-data-item="groupInfo"
      :group-user-array="userArray"
      @refresh-data="refreshGroupData"
    />
  </div>
</template>

<script>
// API
import { onUnmounted } from '@vue/composition-api'
// import moment from 'moment'

// UI
// import {
//   BCard, BRow, BTable, BCol, BPagination, VBTooltip, BDropdown, BImg, BLink, // BBadge,
//   BDropdownForm, BFormInput, BInputGroupPrepend, BInputGroup, BFormGroup, BFormCheckbox, BSkeletonTable,
//   BInputGroupAppend, BModal,
// } from 'bootstrap-vue'
// import vSelect from 'vue-select'
import router from '@/router'
import store from '@/store'

// Component
import { useTodoGroupView } from '../../useTodo' // , useTodoSetting
// import UpdateModal from './GroupListUpdateModal.vue'
import GroupInfo from './GroupViewInfo.vue'
import GroupContent from './GroupViewContent.vue'
import { useSwalToast, useAlert } from '@/libs/mixins/index'
import useStoreModule from '../../useStoreModule'

export default {
  components: {
    // BCard,
    // BRow,
    // BCol,
    // BImg,
    // BLink,
    // BFormInput,
    // BTable,
    // BBadge,
    // BModal,
    // BPagination,
    // BDropdown,
    // BDropdownForm,
    // BInputGroupPrepend,
    // BInputGroupAppend,
    // BInputGroup,
    // BFormGroup,
    // BFormCheckbox,
    // BSkeletonTable,

    // vSelect,
    // UpdateModal,
    GroupInfo,
    GroupContent,
  },
  directives: {
    // 'b-tooltip': VBTooltip,
  },
  mixins: [useSwalToast],
  methods: {
  },
  setup() {
    // 註冊模組
    const TODO_ADMIN_STORE_MODULE_NAME = 'admin-todo'

    if (!store.hasModule(TODO_ADMIN_STORE_MODULE_NAME)) store.registerModule(TODO_ADMIN_STORE_MODULE_NAME, useStoreModule)

    onUnmounted(() => {
      if (store.hasModule(TODO_ADMIN_STORE_MODULE_NAME)) store.unregisterModule(TODO_ADMIN_STORE_MODULE_NAME)
    })

    const paramID = router.currentRoute.params.id

    const {
      isBusy,
      groupInfo,
      userArray,
      getTodoGroupData,
      setTodoGroupUserData,
    } = useTodoGroupView()

    // const {
    //   ui,
    //   statusOptions,
    //   refonlineTime,
    //   onlineTime,
    //   updateOnline,
    // } = useTodoSetting()

    const {
      useAlertToast,
    } = useAlert()

    const refreshGroupData = search => {
      getTodoGroupData(paramID, search)
    }

    refreshGroupData(null)

    return {
      isBusy,
      groupInfo,
      userArray,
      getTodoGroupData,
      refreshGroupData,
      setTodoGroupUserData,
      useAlertToast,
    }
  },
}
</script>
