<template>
  <div>
    <b-overlay
      :show="isCardBusy"
      variant="transparent"
      opacity="0.5"
    >
      <b-card>
        <!-- {{ groupDataItem }} -->
        <div class="d-flex justify-content-between align-items-center mb-1">
          <h4 class="mb-0">
            {{ groupDataItem.name }}
          </h4>

          <div class="text-nowrap d-flex justify-content-end">
            <div
              v-b-tooltip.hover.focus.v-secondary
              title="編輯"
              class="d-flex align-items-center actions-link-btn"
              @click="onSubmitEdit"
            >
              <b-img
                src="/admin/images/table/edit3.svg"
                class="actions-link-btn-image"
                rounded
              />
            </div>

            <div
              v-b-tooltip.hover.focus.v-secondary
              title="重新整理"
              class="d-flex align-items-center actions-link-btn ml-25"
              @click="refreshDeviceData"
            >
              <b-img
                src="/admin/images/table/refresh.svg"
                class="actions-link-btn-image"
                rounded
              />
            </div>
          </div>
        </div>

        <b-row>
          <b-col
            cols="12"
            lg="6"
          >
            <b-form-group
              label-cols="4"
              label="公共"
              class="m-0 selection-group"
            >
              <div class="mt-50">
                <!-- {{ groupDataItem.public }} -->
                <b-badge
                  :variant="ui.groupPublic[1][groupDataItem.public]"
                  class="cursor-pointer"
                  @click="onSubmitEdit"
                >
                  {{ ui.groupPublic[0][groupDataItem.public] }}
                </b-badge>
              </div>
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            lg="6"
          >
            <b-form-group
              label-cols="4"
              label="總數"
              class="m-0 selection-group"
            >
              <div class="mt-50 table-col item-description">
                {{ groupDataItem.use_total ? parseInt(groupDataItem.use_total, 10).toLocaleString() : 0 }}
              </div>
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            lg="6"
          >
            <b-form-group
              label-cols="4"
              label="說明"
              class="m-0 selection-group"
            >

              <div class="d-block selection-group d-flex align-items-center mt-50 mb-25">
                <div
                  class="mr-25 text-nowrap item-description cursor-pointer"
                >
                  {{ groupDataItem.description ? groupDataItem.description : '---' }}
                </div>
                <span
                  class="selection-btn-icon"
                  @click="onSubmitEdit"
                >
                  <b-img
                    src="/admin/images/table/edit.svg"
                    height="14"
                    width="14"
                    class="cursor-pointer mb-25"
                  />
                </span>
              </div>
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            lg="6"
          >
            <b-form-group
              label-cols="4"
              label="上次更新"
              class="m-0 selection-group"
            >
              <div class="mt-50">
                {{ moment(groupDataItem.updated_at).format("YYYY-MM-DD HH:mm") }}
              </div>
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            lg="6"
          >
            <b-form-group
              label-cols="4"
              label="創建時間"
              class="m-0 selection-group"
            >
              <div class="mt-50">
                {{ moment(groupDataItem.created_at).format("YYYY-MM-DD HH:mm") }}
              </div>
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>
    </b-overlay>

    <!-- 編輯主機 -->
    <edit-modal
      ref="editModal"
      :group-data-item="groupDataItem"
      @refetch-data="refreshDeviceData"
    />
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormGroup, BImg, VBTooltip, BOverlay, BBadge,
} from 'bootstrap-vue'
import moment from 'moment'
import { useTodoSetting } from '../../useTodo' // , useTodoSetting
import EditModal from '../todo-list/GroupListUpdateModal.vue'

export default {
  components: {
    BImg,
    BRow,
    BCol,
    BCard,
    BBadge,
    BOverlay,
    BFormGroup,

    EditModal,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    isCardBusy: {
      type: Boolean,
      required: true,
    },
    groupDataItem: {
      type: Object,
      required: true,
    },
  },
  // computed: {
  //   authAbility() {
  //     return this.$store.state.app.ability
  //   },
  // },
  methods: {
    moment,
    refreshDeviceData() {
      if (this.isCardBusy) return
      this.$emit('refresh-data', 'info')
    },

    // (觸發)編輯主機
    onSubmitEdit() {
      // if (this.authAbility.user !== 'write') return
      setTimeout(() => { this.$refs.editModal.getData() }, 200)
    },
  },
  setup() {
    const {
      ui,
    } = useTodoSetting()

    return {
      ui,
    }
  },
}
</script>

<style lang="scss" scoped>
.item-description {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 200px;
}
</style>
